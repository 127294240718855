<template>
    <div class="div">
        <div class="body">
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyE/douyinE001.png" alt="" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyE/douyinE002.gif" alt="" @click="throttleSubmit" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyE/douyinE003.png" alt="" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyE/douyinE004.gif" alt="" @click="throttleSubmit" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyE/douyinE005.png" alt="" />
            <div class="bottomText" v-if="tg_id == 2">苏州安捷成文化科技有限公司</div>
            <div class="bottomText" v-else>上海河山汇文化科技有限公司</div>

            <img src="https://oss.hshwhkj.com/images/3.gif" alt="" class="wx" @click="throttleSubmit" />
            <img src="https://oss.hshwhkj.com/images/360/1.gif" alt="" class="bto" @click="throttleSubmit" />
        </div>

        <el-dialog :visible.sync="dialogFormVisible" width="82%" :lock-scroll="true" :close-on-click-modal="false">
            <div v-if="flages">
                <div style="" class="textTitle">
                    1.请问您对《易经》的了解程度如何? <span style="color: red">*</span>
                </div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in texts"
                        :key="index"
                        @click="handleClick(index)"
                        :class="{active: index === selected}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="textTitle">2.请问您希望学习《易经》中的哪些知识? <span style="color: red">*</span></div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in textsA"
                        :key="index"
                        @click="throttleSubmitA(index)"
                        :class="{active: index === selectedA}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 确认弹框 -->
        <el-dialog
            :visible.sync="suessesDir"
            width="82%"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            custom-class="dirClass"
        >
            <div v-if="dirFlag">
              <div style="margin-top: -40px;margin-bottom: 20px;" class="phonesCss">输入您所在的城市，领取福利</div>
                <el-form  >
                    <el-form-item >
                        <el-input placeholder="信息已加密，请放心填写" v-model="phones" ></el-input>
                    </el-form-item>
                    <el-button style="width: 100%" @click="phoneFlag" round class="submitbtns" 
                        :class="{activeSubmit: phones.length >1 }"
                    
                    >提交</el-button>
                </el-form>
            </div>

            <div v-else>
                <div class="imgsA">
                    <img src="https://oss.hshwhkj.com/images/succeed.png" alt="" />
                </div>
                <div class="imgsB" @click="throttleSubmitB">
                    <img src="https://oss.hshwhkj.com/images/submitA.png" alt="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
//引入
import {getWxCsad} from "../../api/360AD.js";

export default {
    name: "tiktokAD",
    components: {},
    data() {
        return {
          phones:'',
          dirFlag:false,
            flages: true,
            dialogFormVisible: false,
            suessesDir: false,
            texts: ["略有一些了解", "有一些自己见解", "不是很了解"],
            textsA: ["面相识人学", "旺运姓名学", "家居风水学", "八字命理学"],
            selected: null,
            selectedA: null,
            tg_id: "",
            form: {
                name: "12",
            },
            ruleForm: {
                land_link: "", //落地页链接
            },
        };
    },
    created() {
        this.ruleForm.land_link = window.location.href;
        this.tg_id = this.$route.query.tg_id;
    },
    methods: {
   
      phoneFlag(){
        if (this.phones.length>1) {
        this.dirFlag=false
        }else{
          this.$message({type: "error", message: "请您输入您的所在城市"});
        }
      },
        handleClick(index) {
            this.selected = index;
            this.flages = false;
        },
        // handleClickA(index) {
        //     this.selectedA = index;
        //     this.wxClick();
        // },
        throttleSubmit() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 2500) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间
            // this.openDir(); // 调用实际的支付方法
        // this.$router.push(
        //       {
        //         path: "/wenda",
        //         query: {
        //           tg_id: this.tg_id,
        //     land_link: this.ruleForm.land_link,
        //         }
        //       }
        //     )
        this.wxClick()
        },
        throttleSubmitA(index) {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间

            this.selectedA = index;
            this.dialogFormVisible = false;
            this.suessesDir = true;
            this.phones=''
            this.dirFlag=false
            this.flages = true;

            // this.wxClick(); // 调用实际的支付方法
        },
        throttleSubmitB() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }

            this.lastClickTime = currentTime; // 更新上次点击时间
            // this.wxClick(); // 调用实际的支付方法
            this.$router.push(
              {
                path: "/wenda",
                query: {
                  tg_id: this.tg_id,
            land_link: this.ruleForm.land_link,
                }
              }
            )
        },
        // 打开弹框
        openDir() {
            this.dialogFormVisible = true;
        },
        closeDir() {
            this.dialogFormVisible = false;
            this.flages = true;
            this.selected = null;
            this.selectedA = null;
        },
      async wxClick() {
            // console.log(this.selected);
            // console.log(this.selectedA);
            // if (this.selected === null || this.selectedA === null) {
            //     this.$message({type: "error", message: "请选择选项"});
            // } else {
                const {data} = await getWxCsad(this.ruleForm);
                // console.log(data);
                window.location.href = data.data;
                // this.dialogFormVisible = false;
            // }
        },
    },
};
</script>

<style scoped lang="less">
* {
    box-sizing: border-box;
}
.div {
    width: 100%;
    overflow: hidden;
.phonesCss{
  font-weight: 700;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}
.submitbtns{
  color: #adadad;
  background-color: #e8e8e8;
}
.activeSubmit{
   color: #fff;
  background-color: #c83732;
}
    .center {
        display: flex;
        width: 100%;
        height: 60px;
        line-height: 60px;
        align-items: center;
        background-color: #fff;
        .img {
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 0 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .text {
            color: rgb(0, 0, 0);

            font-size: 24px;
        }
        .btn {
            margin-left: 50px;
            width: 80px;
            height: 40px;
            background-color: #11a91d;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            font-size: 22px;
            color: #fff;
            animation: btn 0.6s infinite;
        }
    }
    .bto {
        display: flex;
        width: 100%;
        line-height: 60px;
        align-items: center;
        background-color: #fff;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
    }
    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 60px;
        overflow: hidden;
        img {
            width: 100%;
            object-fit: contain;
        }
        .wx {
            position: fixed;
            top: 50%;
            right: 0;
            width: 90px;
            height: 90px;
            object-fit: contain;
            cursor: pointer;
        }
        .bottomText {
            width: 100%;
            text-align: center;
            font-family: SimSun, "宋体", STSong, "华文宋体", serif;
            color: rgba(115, 114, 110, 1);
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
    .imgsA {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    .imgsB {
        margin-top: 20px;
        width: 275px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .dirClass {
        border-radius: 12px;
    }
}
@media screen and (min-width: 750px) {
    .div {
        width: 750px;
        overflow: hidden;
        margin: 0 auto;
        .body {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 750px;
            margin-top: 60px;
            .wx {
                position: fixed;
                top: 50%;
                right: 28%;
                width: 90px;
                height: 90px;
                object-fit: contain;
                cursor: pointer;
            }
            .bottomText {
                width: 100%;
                text-align: center;
                font-family: SimSun, "宋体", STSong, "华文宋体", serif;
                color: rgba(115, 114, 110, 1);
                font-size: 24px;
                margin-top: 10px;
            }
            .bto {
                width: 750px;
                cursor: pointer;
                position: fixed;
                cursor: pointer;
                bottom: 0;
            }
        }
        .textTitle {
            margin: 0 auto;
            width: 200px;
        }
        .textTitle1 {
            margin: 0 auto;
            width: 200px;
            font-weight: 700;
        }
        .textTitle {
            font-size: 16px;
            margin: 0 auto;
            font-weight: 700;
        }
        .texts {
            margin: 0 auto;
            background-color: #f8f9fb;
            height: 42px;
            width: 263px;
            line-height: 42px;
            text-align: center;
            margin-top: 20px;
            border-radius: 12px;
            font-size: 16px;
        }
        .texts.active {
            /* 被点击时的样式 */
            background-color: #e7eeff;
            border: #b9cdfe 1px solid;
            border-radius: 12px;
            font-size: 16px;
        }
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
    .imgsA {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    .imgsB {
        width: 275px;
        height: 60px;
        margin: 0 auto;
        margin-top: 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
@keyframes btn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
